/* eslint-disable react/jsx-props-no-spreading */
import Slider from 'react-slick'

import styles from './AwardsCarousel.module.css'

import award1 from './media/postparcel.jpg'
import award2 from './media/vraward.jpg'
import award3 from './media/contactless.jpg'
import award4 from './media/kepdienste.jpg'
import award5 from './media/innovativste.jpg'
import award6 from './media/ibm.jpg'
import award7 from './media/vision.jpg'
import award8 from './media/ideen.jpg'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
}

const AwardsCarousel = () => (
  <Slider {...settings} className={styles.slider}>
    <div>
      <img src={award1} alt="World Post & Parcel Award (Innovation)" />
      <span>
        2020
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award1')}
      </span>
    </div>
    <div>
      <img src={award2} alt="VR Award (Digitization)" />
      <span>
        2020
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award2')}
      </span>
    </div>
    <div>
      <img src={award3} alt="Top Contactless Delivery Provider in Europe" />
      <span>
        2020
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award3')}
      </span>
    </div>
    <div>
      <img
        src={award4}
        alt="Europ. Preis für Nachhaltigkeit, Sonderpreis KEP-Dienste"
      />
      <span>
        2020
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award4')}
      </span>
    </div>
    <div>
      <img src={award5} alt="Innovativste Unternehmen Deutschland, Capital" />
      <span>{I18n.t('pages.home.awards.awards_carousel_award5')}</span>
    </div>
    <div>
      <img src={award6} alt="Winner IBM SmartCamp Berlin" />
      <span>
        2014
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award6')}
      </span>
    </div>
    <div>
      <img src={award7} alt="VisionAward" />
      <span>
        2013
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award7')}
      </span>
    </div>
    <div>
      <img src={award8} alt="Ausgewählter Ort, Deutschland Land der Ideen" />
      <span>
        2012
        <br />
        {I18n.t('pages.home.awards.awards_carousel_award8')}
      </span>
    </div>
  </Slider>
)

export default AwardsCarousel
